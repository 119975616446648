import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Modal, ModalBody, Label } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { del, download, get, post, put } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import * as Utils from "../../Utils";
import UserProfile from "../../components/UserProfile";
import Loader from "../../components/Loader";
import moment from "moment-timezone";
import { useLocation, useParams } from 'react-router-dom';

export default function Withdarwal() {
    const location = useLocation();
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [profileModal, setProfileModal] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [moneyModal, setMoneyModal] = useState(false);
    const [subId, setSubId] = useState("");
    const [userId, setUserId] = useState('');
    const [walletHistory, setWalletHistory] = useState([]);
    const [walletColumns, setWalletColumns] = useState([]);
    const [txnStatus, setTxnStatus] = useState("Requested");
    const [exportModal, setExportModal] = useState(false);

    // console.log('location', location)

    useEffect(() => {
        if (user?.token)
            getData();
    }, [user]);

    useEffect(() => {
        if (location?.state?.userId) {
            // console.log("user id on task assignment", location?.state?.userId)
            setUserId(location?.state?.userId)
            getData(location?.state?.userId);
            setProfileModal(false);
        }
    }, [location?.state]);

    useEffect(() => {
        if (moneyModal) {
            // console.log('currrent data', currentData?.tasks?._id);
            post("wallet/list", { userId: currentData?.userId, type: "Withdraw", status: "Approved", token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setWalletHistory(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }, [moneyModal]);

    useEffect(() => {
        setColumns([
            {
                name: 'Cust Id',
                selector: row => row?.custId,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Name',
                selector: row => row?.name,
                sortable: true,

            },
            {
                name: 'Amount',
                selector: row => "₹ " + Number(row?.amount).toLocaleString(),
                sortable: true,
                maxWidth: '100px'
            },
            {
                name: 'Wallet Balance',
                selector: row => "₹ " + Number(row?.wallet).toLocaleString(),
                sortable: true,
                maxWidth: "120px"
            },
            {
                name: 'GST',
                selector: row => row?.gstNo ? "Yes" : "No",
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Status',
                selector: row => row?.status,
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row)}
                        title={row?.status}
                        className={`btn_status`}
                        style={{ backgroundColor: row?.status == "Requested" ? "#f59d18" : row?.status == "Approved" ? Utils.themeColorDark : "#f51818" }}
                    >
                        <p className="text-white" style={{ fontSize: 16, marginBottom: 0 }}>
                            {row?.status}
                        </p>
                    </Button>
                </>,
                sortable: true,
                maxWidth: "150px"
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    {/* <Button onClick={() => { setCurrentData(row); setTaskListModal(true) }}
                        title={"Tasks"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-list-check" style={{ fontSize: 20 }}></i></Button> */}
                    {/* <Button onClick={() => { setCurrentData(row); setTaskModal(true) }}
                        title={"Assign"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-add-line" style={{ fontSize: 20 }}></i></Button> */}
                    {/* <Button onClick={() => { setCurrentData(row); setMoneyModal(true) }}
                        title={"Amount"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-money-dollar-box-line" style={{ fontSize: 20 }}></i></Button> */}
                    <Button onClick={() => { setCurrentData(row); setProfileModal(true) }}
                        title={"View"}
                        style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><i className="ri-eye-fill" style={{ fontSize: 20 }}></i></Button>
                    {/* {
                        checkPermission('city', 'update') &&
                        <Button onClick={() => handleUpdateProj(row)}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 20 }}></span></Button>
                    }
                    {
                        checkPermission('city', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row)}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    } */}
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '100px'
            },
        ])
    }, [data]);

    useEffect(() => {
        setWalletColumns([
            {
                name: 'Amount',
                selector: row => "₹ " + row?.amount,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Type',
                selector: row => row?.type,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Balance',
                selector: row => "₹ " + row?.balance,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Credited At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
            }
        ])
    }, [walletColumns]);

    useEffect(() => {
        if (txnStatus)
            getData();
    }, [txnStatus]);

    const getData = () => {
        if (!loading) {
            if (user?.token) {
                setLoading(true);
                get("wallet/request?status=" + txnStatus, { token: user?.token })
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setData(res?.data);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleValidSubmit = (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                userId: currentData?._id,
                token: user?.token
            }
            post("task", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        getData(currentData?.userId);
                    } else
                        toast.error("" + res?.error);
                })
                .catch(err => {
                    setLoading(false);
                    console.error("error while updating data", err);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleValidSubmitAmount = (e, v) => {
        if (!loading) {
            // setLoading(true);
            let body = {
                ...v,
                userId: currentData?.userId,
                status: txnStatus,
                txnId: currentData?._id,
                planId: currentData?.txn?._id,
                token: user?.token
            }
            put("wallet/request", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setMoneyModal(false);
                        getData();
                    } else
                        toast.error("" + res?.error);
                })
                .catch(err => {
                    setLoading(false);
                    console.error("error while updating data", err);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleDownload = async (item, index) => {
        try {
            const response = await fetch(item?.doc, {
                mode: 'cors',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const link = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            link.href = objectUrl;
            link.setAttribute('download', item?.bookName + ".doc");
            document.body.appendChild(link);
            link.click();
            link.remove();
            URL.revokeObjectURL(objectUrl); // Free up memory when done
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    const onConfirm = (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                taskId: currentData?.tasks?._id,
                subId,
                status: currentData?.to,
                remark: v?.remark,
                token: user?.token
            }
            if (currentData?.actionType == 'Task') {
                put("task/sub", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData(userId);
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'Delete') {
                del("transaction", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData(userId)
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleStatus = (item, to) => {
        setCurrentData({ ...currentData, actionType: "Task", to })
        setSubId(item?._id);
        setConfirm(true);
    }

    const handleUpdateStatus = (row) => {
        if (checkPermission("withdrawRequest", "update")) {
            setCurrentData({ ...row, actionType: "Status" });
            setMoneyModal(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;

        }
    }

    const handleValidExport = (e, v) => {
        let body = {
            ...v,
            token: user?.token
        }
        download("wallet/export", body)
            .then(blob => {
                setLoading(false);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'withdraw.csv';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setExportModal(false);
            })
            .catch(error => {
                setLoading(false);
                console.log('error while getting project list', error);
            });
    }

    const getCharge = () => {
        let total = Number(currentData?.amount);
        let tax = (total * 10) / 100;
        return tax;
    }

    const getGst = () => {
        let total = Number(currentData?.amount) - getCharge();
        let tax = total / 118 * 100;
        return total - tax;
    }

    const getWithdraw = () => {
        let total = Number(currentData?.amount);
        let tax = getCharge();
        // console.log("charge", tax);
        let afterCharge = total - tax;
        // console.log("after charge", afterCharge);
        let gst = getGst();
        // console.log("gst", gst);
        // console.log("after gst", afterCharge - gst);
        return afterCharge - gst;
    }

    const getOriginalAmountFromAfterCharge = (withdrawable) => {
        const SERVICE_CHARGE_PERCENTAGE = 10; // 10%
        const GST_PERCENTAGE = 18;

        // Calculate GST from the withdrawable amount
        const gst = (withdrawable * GST_PERCENTAGE) / (100 - GST_PERCENTAGE);
        console.log("Calculated GST: ", gst);

        // Amount after charge before GST
        const amountAfterChargeBeforeGST = withdrawable + gst;
        console.log("Amount After Charge Before GST: ", amountAfterChargeBeforeGST);

        // Calculate the original amount
        const originalAmount = amountAfterChargeBeforeGST / (1 - (SERVICE_CHARGE_PERCENTAGE / 100));
        console.log("Calculated Original Amount: ", originalAmount);

        return originalAmount;
    };
    // console.log('current data on task', currentData);

    return (
        <React.Fragment>
            <Header />
            <Loader visible={loading} />
            <UserProfile
                show={profileModal}
                onCloseClick={() => setProfileModal(false)}
                userId={currentData?.userId}
            />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            <Modal isOpen={moneyModal} style={{ maxWidth: 800 }} centered>
                <ModalBody className='mx-2'>
                    <CardTitle><h5><b>{currentData?.name} requested for withdraw</b></h5></CardTitle>
                    <CardBody>
                        <Row className='mt-4'>
                            <Col md={6}>
                                <Label><strong>Amount Summary</strong></Label>
                                <p className='mb-1'>Amount Requested : <b>₹{currentData?.amount}</b></p>
                                <p className='mb-1'>Service Charge (10%) : <b>-₹{getCharge()}</b></p>
                                <p className='mb-1'>With GST : <b>₹{currentData?.amount - getCharge()}</b></p>
                                <p className='mb-1'>GST (18%) : <b>-₹{getGst().toFixed(2)}</b></p>
                                <p>Widthrawable Amount : <b>₹{getWithdraw().toFixed(2)}</b></p>
                            </Col>
                            <Col md={6}>
                                <Label><strong>Plan Summary</strong></Label>
                                <p className='mb-1'>Plan : <b>{currentData?.txn?.title}  (₹{currentData?.txn?.amount})</b></p>
                                <p className='mb-1'>Plan due : <b>₹{currentData?.txn?.due}</b></p>
                                <p className='mb-1'>Plan Deduction (60%) : <b>₹{(getWithdraw() * 0.6).toFixed(2)}</b></p>
                                <p>Widthrawable Amount : <b>₹{(getWithdraw() - (getWithdraw() * 0.6)).toFixed(2)}</b></p>
                            </Col>
                        </Row>
                        <AvForm onValidSubmit={handleValidSubmitAmount}>
                            <Row>
                                <Row>
                                    <Col md={12}>
                                        <div className='mt-4'>
                                            <AvField
                                                name="transfer"
                                                type="number"
                                                label="Transfered Amount *"
                                                placeholder="Enter the amount"
                                                required
                                                value={currentData?.txn?.due ? (getWithdraw() - (getWithdraw() * 0.6)).toFixed(2) : getWithdraw().toFixed(2)}
                                                disabled
                                                validate={{
                                                    number: { value: true, errorMessage: 'Please enter a valid number' },
                                                    required: { value: true, errorMessage: 'This field is required' },
                                                    max: { value: currentData?.txn?.due ? (getWithdraw() - (getWithdraw() * 0.6)).toFixed(2) : getWithdraw().toFixed(2), errorMessage: `Amount should not exceed ${currentData?.txn?.due ? (getWithdraw() - (getWithdraw() * 0.6)).toFixed(2) : getWithdraw().toFixed(2)}` }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='mt-2'>
                                            <AvField
                                                name="utrNo"
                                                type="text"
                                                label="UTR Number *"
                                                placeholder="Enter UTR Number"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='mt-2'>
                                            <AvField
                                                name="remark"
                                                type="textarea"
                                                label="Remark *"
                                                placeholder="Enter the remark"
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Col md={12} className='mt-2'>
                                    <div className='d-flex justify-content-around mb-3'>
                                        <Button type="reset" className="btn-sign"
                                            onClick={() => { setCurrentData(null); setMoneyModal(false) }}
                                            style={{ backgroundColor: Utils.themeColor, width: "30%" }}>
                                            Cancel</Button>
                                        <Button type="submit" className="btn-sign" onClick={() => setTxnStatus("Rejected")}
                                            style={{ backgroundColor: "#f51818", width: "30%" }}>
                                            Reject Request</Button>
                                        <Button type="submit" className="btn-sign" onClick={() => setTxnStatus("Approved")}
                                            style={{ backgroundColor: Utils.themeColor, width: "30%" }}>
                                            Deduct form wallet</Button>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <DataTable
                                        columns={walletColumns}
                                        data={walletHistory}
                                        pagination
                                        conditionalRowStyles={[{
                                            when: row => row?.style,
                                            style: row => ({ width: row?.style?.width }),
                                        },
                                        ]}
                                        customStyles={{
                                            headCells: {
                                                style: {
                                                    color: 'black',
                                                    fontWeight: 'bold',
                                                    fontSize: 15,
                                                    width: 0
                                                },
                                            },
                                            cells: {
                                                style: {
                                                    width: 0
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </ModalBody>
            </Modal>
            <Modal isOpen={exportModal} style={{ maxWidth: 800 }} centered>
                <ModalBody className='mx-2'>
                    <CardTitle><h5><b>Export CSV</b></h5></CardTitle>
                    <CardBody>
                        <AvForm onValidSubmit={handleValidExport}>
                            <Row>
                                <Row className='mt-4'>
                                    <Col md={6}>
                                        <div>
                                            <AvField
                                                name="from"
                                                type="date"
                                                label="Date From *"
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <AvField
                                                name="to"
                                                type="date"
                                                label="Date To *"
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Col md={12} className='mt-4'>
                                    <div className='d-flex justify-content-around mb-3'>
                                        <Button type="reset" className="btn-sign"
                                            onClick={() => { setCurrentData(null); setExportModal(false) }}
                                            style={{ backgroundColor: Utils.themeColor, width: "40%" }}>
                                            Cancel</Button>
                                        <Button type="submit" className="btn-sign"
                                            style={{ backgroundColor: Utils.themeColor, width: "40%" }}>
                                            Export</Button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </ModalBody>
            </Modal>
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <AvForm className="mx-3" onValidSubmit={(e, v) => { setUserId(v?.search); getData(v?.search) }}>
                        <Row className='mb-4 align-items-center'>
                            <Col md={10}>
                                <CardBody className='card-header-box' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <div>
                                        <CardTitle><b>Withdarwal Request</b></CardTitle>
                                        <CardSubtitle>Users who have requested for <code> Withdrawal.</code></CardSubtitle>
                                    </div>
                                </CardBody>
                            </Col>
                            <Col md={2}>
                                <div className='w-100' style={{}} onClick={() => setExportModal(true)}>
                                    <Button type="button" className="btn-sign" style={{ float: 'right', width: "100%" }}>Export CSV</Button>
                                </div>
                            </Col>
                            {/* <Col md={8} className='mt-4'>
                                <AvField
                                    name="search"
                                    placeholder="Search user by Cust Id"
                                    required
                                    value={userId}
                                // className="search-input"
                                />
                            </Col>
                            <Col md={2} className='mt-2'>
                                <div className='w-100' style={{}}>
                                    <Button type="submit" className="btn-sign" style={{ float: 'right', width: "100%" }}>Search</Button>
                                </div>
                            </Col>
                            <Col md={2} className='mt-2'>
                                <div className='w-100' style={{}}>
                                    <Button type="reset" className="btn-sign" style={{ float: 'right', width: "100%" }}
                                        onClick={() => { setData([]); setUserId(""); }}
                                    >Reset</Button>
                                </div>
                            </Col> */}
                            <Col md={8}>
                                <Row>
                                    <Col md={2} className='mt-2'>
                                        <div className='w-100' style={{}}>
                                            {txnStatus == "Requested" ?
                                                <Button type="button" className="btn-sign" style={{ backgroundColor: "#f59d18", width: "100%" }}>Requested</Button>
                                                :
                                                <Button type="button" className="btn-inactive" style={{ width: "100%" }} onClick={() => setTxnStatus("Requested")}>Requested</Button>
                                            }
                                        </div>
                                    </Col>
                                    <Col md={2} className='mt-2'>
                                        <div className='w-100' style={{}}>
                                            {txnStatus == "Approved" ?
                                                <Button type="button" className="btn-sign" style={{ backgroundColor: Utils.themeColorDark, width: "100%" }}>Approved</Button>
                                                :
                                                <Button type="button" className="btn-inactive" style={{ width: "100%" }} onClick={() => setTxnStatus("Approved")}>Approved</Button>
                                            }
                                        </div>
                                    </Col>
                                    <Col md={2} className='mt-2'>
                                        <div className='w-100' style={{}}>
                                            {txnStatus == "Rejected" ?
                                                <Button type="button" className="btn-sign" style={{ backgroundColor: "#f51818", width: "100%" }}>Rejected</Button>
                                                :
                                                <Button type="button" className="btn-inactive" style={{ width: "100%" }} onClick={() => setTxnStatus("Rejected")}>Rejected</Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </AvForm>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    width: 0
                                },
                            },
                            cells: {
                                style: {
                                    width: 0
                                }
                            }
                        }}
                    />
                </Card>
                <Footer />
            </div>
        </React.Fragment >
    )
}
