import React, { useEffect, useState, useRef } from 'react';
import { get, post, put } from '../../helper/api_helper';
import { CardTitle, Col, Modal, ModalBody, Row, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import "./style.css";
import moment from 'moment-timezone';
import * as  Utils from "../../Utils";
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../ConfirmModal';

function UserProfile(props) {
    // console.log('props on profile', props);
    const navigate = useNavigate()
    const user = useSelector(state => state.user);
    const { userId, show, onCloseClick, txn, setCurrentData, setConfirm, getData } = props;
    const [userData, setUserData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [isManualPay, setIsManualPay] = useState(false);
    const [plans, setPlans] = useState([]);
    const form = useRef();
    const [imageModal, setImageModal] = useState(false);
    const [currentImage, setCurrentImage] = useState(undefined);
    const [temp, setTemp] = useState(null);
    const [payConfirm, setPayConfirm] = useState(false);
    const [dueModal, setDueModal] = useState(false);
    const [bankModal, setBankModal] = useState(false);

    useEffect(() => {
        if (userId)
            getUserData();
    }, [userId]);

    const getUserData = () => {
        if (!loading) {
            setLoading(true);
            get("user/detail?userId=" + userId, { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setUserData(res?.data);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    console.log('error while getting user', err);
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleValidSubmit = (e, v) => {
        let body = {
            ...v,
            token: userData?.token
        }
        post("/plan/subscribe", body)
            .then(res => {
                if (res?.statusCode == 200) {
                    toast.success(res?.message);
                    setIsManualPay(false);
                    getUserData();
                    // console.log("for ref", form);
                    // form.reset();
                }
            })
            .catch(err => {
                console.log("error while manual txn", err);
                toast.error("Something Went Wrong!");
            })
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;
        }
    }

    const onPayConfirm = () => {
        let body = {
            txnId: temp?._id,
            status: "Rejected",
            expire: true,
            token: user?.token
        }
        put("transaction", body)
            .then(res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    setPayConfirm(false);
                    onCloseClick(false);
                    getUserData();
                    if (getData)
                        getData();
                } else
                    toast.error("" + res?.error);
            })
            .catch(err => {
                setLoading(false);
                console.error("error while updating data", err);
                toast.error("Something Went Wrong!");
            })
    }

    const handleValidDue = (e, v) => {
        if (userData?.trans?.length) {
            let body = {
                txnId: userData?.trans[0]?._id,
                due: v?.due,
                token: user?.token
            }
            console.log("value on due submit", body);
            put("transaction", body)
                .then(res => {
                    if (res?.statusCode == 200) {
                        getUserData();
                        setDueModal(false);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    console.error("error while updating due", err);
                    toast.error("Something Went Wrong!");
                })
        } else {
            toast.error("Plan Transaction not found!");
        }
    }

    const handleValidBank = (e, v) => {
        let body = {
            ...v,
            userId: userData?._id,
            token: user?.token
        }
        console.log("value on due submit", body);
        put("user", body)
            .then(res => {
                if (res?.statusCode == 200) {
                    getUserData();
                    setBankModal(false);
                } else {
                    toast.error(res?.error);
                }
            })
            .catch(err => {
                console.error("error while updating due", err);
                toast.error("Something Went Wrong!");
            })
    }

    return (
        <Modal isOpen={show} toggle={onCloseClick} className='modal-full-screen' centered={true} style={{ maxWidth: '100%' }}>
            <button className='close_btn' onClick={() => onCloseClick(false)}> <i class="ri-close-line"></i></button>
            <ModalBody className="py-3 px-5">
                <Modal isOpen={imageModal} toggle={() => setImageModal(false)} className='' centered={true} style={{}}>
                    <button className='close_btn' onClick={() => setImageModal(false)}> <i class="ri-close-line"></i></button>
                    <ModalBody className="py-3 px-5">
                        <img src={currentImage} style={{ width: "100%", height: "auto" }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={dueModal} toggle={() => setDueModal(false)} className='' centered={true} style={{}}>
                    <button className='close_btn' onClick={() => setDueModal(false)}> <i class="ri-close-line"></i></button>
                    <ModalBody className="py-3 px-5">
                        <h5>Update Amount Due</h5>
                        <AvForm onValidSubmit={handleValidDue}>
                            <div className='mb-2 mt-4'>
                                <AvField
                                    name="due"
                                    label="Due Amount *"
                                    placeholder="Enter due amount"
                                    type="number"
                                    value={userData?.trans?.length ? userData?.trans[0]?.due : 0}
                                    required
                                />
                            </div>
                            <Row className='mt-2'>
                                <Col md={6}>
                                    <div className="text-center mt-2 w-100">
                                        <button
                                            type="submit"
                                            className="btn btn-success btn-lg me-2 w-100"
                                        >   Update
                                        </button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="text-center mt-2 w-100">
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-lg me-2 w-100"
                                            onClick={() => setDueModal(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>
                <Modal isOpen={bankModal} toggle={() => setBankModal(false)} className='' centered={true} style={{ maxWidth: 800 }}>
                    <button className='close_btn' onClick={() => setBankModal(false)}> <i class="ri-close-line"></i></button>
                    <ModalBody className="py-3 px-5">
                        <h5>Update {userData?.name}'s Bank Details</h5>
                        <AvForm onValidSubmit={handleValidBank}>
                            <Row>
                                <Col md={6}>
                                    <div className='mb-2 mt-4'>
                                        <AvField
                                            name="bankName"
                                            label="Bank Name *"
                                            placeholder="Enter Bank Name"
                                            type="text"
                                            value={userData?.bankName ? userData?.bankName : ""}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-2 mt-4'>
                                        <AvField
                                            name="branch"
                                            label="Branch Name *"
                                            placeholder="Enter Bank Name"
                                            type="text"
                                            value={userData?.branch ? userData?.branch : ""}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-2 mt-4'>
                                        <AvField
                                            name="ifscCode"
                                            label="IFSC Code *"
                                            placeholder="Enter IFSC Code"
                                            type="text"
                                            value={userData?.ifscCode ? userData?.ifscCode : ""}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-2 mt-4'>
                                        <AvField
                                            name="acNumber"
                                            label="Account Number *"
                                            placeholder="Enter Account Number"
                                            type="number"
                                            value={userData?.acNumber ? userData?.acNumber : ""}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-2 mt-4'>
                                        <AvField
                                            name="upiId"
                                            label="UPI Id *"
                                            placeholder="Enter UPI Id"
                                            type="text"
                                            value={userData?.upiId ? userData?.upiId : ""}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-2 mt-4'>
                                        <AvField
                                            name="upiNumber"
                                            label="UPI Number *"
                                            placeholder="Enter UPI Number"
                                            type="number"
                                            value={userData?.upiNumber ? userData?.upiNumber : ""}
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col md={6}>
                                    <div className="text-center mt-2 w-100">
                                        <button
                                            type="submit"
                                            className="btn btn-success btn-lg me-2 w-100"
                                        >   Update
                                        </button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="text-center mt-2 w-100">
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-lg me-2 w-100"
                                            onClick={() => setBankModal(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>
                <ConfirmModal
                    show={payConfirm}
                    onConfirm={onPayConfirm}
                    onCloseClick={() => setPayConfirm(false)}
                    data={temp}
                />
                <Row>
                    <Col md={12}>
                        <div className='profile_container'>
                            <img className='profile_image' src={userData?.profile_picture} />
                            <div className='user-detail-box'>
                                <h5 className='text-left text-uppercase '><span className='one'>{userData?.name} |</span><span className='two'>{userData?.userId} |</span> <span className='three'>{userData?.trans[0]?.title}</span><button onClick={() => navigate('/task', { state: { userId: userData?.userId } })}>View Task</button></h5>

                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='box-gary'>
                    <Col md={6} >
                        <Row>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Customer ID :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1 text-start'>
                                <span className='value'><strong>{userData?.userId}</strong></span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Registered On :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1 text-start'>
                                <span className='value'>{moment.tz(userData?.createdAt, 'Asia/Kolkata').format("DD MMM, YYYY hh:mm a")}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>KYC Completed On :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1 text-start'>
                                <span className='value'>{moment.tz(userData?.updatedAt, 'Asia/Kolkata').format("DD MMM, YYYY hh:mm a")}</span>
                            </Col>

                            {userData?.trans?.length ?
                                <>
                                    <Col md={6} className='mt-1'>
                                        <span className='key'><strong>Subscribed on :</strong> </span>
                                    </Col>
                                    <Col md={6} className='mt-1 text-start'>
                                        <span className='value'>{moment.tz(userData?.trans[0]?.createdAt, 'Asia/Kolkata').format("DD MMM, YYYY hh:mm a")}</span>
                                    </Col>
                                    <Col md={6} className='mt-1'>
                                        <span className='key'><strong>Expiry :</strong> </span>
                                    </Col>
                                    <Col md={6} className='mt-1 text-start'>
                                        <span className='value'>{moment.tz(userData?.expiry, 'Asia/Kolkata').format("DD MMM, YYYY hh:mm a")}</span>
                                    </Col>
                                    <Col md={6} className='mt-1'>
                                        <span className='key'><strong>Plan :</strong> </span>
                                    </Col>
                                    <Col md={6} className='mt-1 text-start'>
                                        <span className='value text-uppercase'><strong>{userData?.trans[0]?.title}</strong></span>
                                    </Col>

                                </>
                                : null}
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Phone :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'><strong>{userData?.mobile}</strong></span>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            {/* <Col md={6} className=''>
                                <span className='key'>Device : </span>
                            </Col>
                            <Col md={6} className='mt-5'>
                                <span className='value text-uppercase'>{userData?.deviceType}</span>
                            </Col> */}

                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Email :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.email}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>DOB :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{moment.tz(userData?.dob, 'Asia/Kolkata').format("DD MMM, YYYY")}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>State :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.state}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>District :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.district}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Pin Code :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.pinCode}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Address :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.address}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Wallet Balance :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'><b>₹ {userData?.wallet}</b></span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <h5 className='my-4 text-uppercase text-dark' style={{ display: 'flex', alignItems: 'center', gap: 5 }}> <i class="ri-file-line" style={{ color: '#4bac4f' }}></i><b>Document Details</b></h5>
                        <Row className='box-gary'>

                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Document Type :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.docType}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>{userData?.docType} Number :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.docNumber}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Document Image :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <Button
                                    onClick={() => { setCurrentImage(userData?.docImage); setImageModal(true) }}
                                    title={"Receipt"}
                                    className={`btn_status btn_status_new active`}
                                >
                                    <i className='ri-image-line' style={{ fontSize: 12, color: '#fff' }} />
                                </Button>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Document Type :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'><strong>Pan Card :</strong></span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>{"Pan Card"} Number :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.panNumber}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Pan Image :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <Button
                                    onClick={() => { setCurrentImage(userData?.panImage); setImageModal(true) }}
                                    title={"Receipt"}
                                    className={`btn_status btn_status_new active`}
                                >
                                    <i className='ri-image-line' style={{ fontSize: 12, color: '#fff' }} />
                                </Button>
                            </Col>

                        </Row>
                    </Col>
                    <Col lg={6}>
                        <h5 className='my-4 text-uppercase text-dark' style={{ display: 'flex', alignItems: 'center', gap: 5 }}> <i class="ri-bank-fill" style={{ color: '#4bac4f' }}></i><b>Bank Details</b> <i class="ri-edit-line" style={{ color: '#4bac4f', cursor: 'pointer' }} onClick={() => setBankModal(true)}></i></h5>
                        <Row className='box-gary'>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Bank Name :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.bankName}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Branch Name :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.branch}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>IFSC Code :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.ifscCode}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>Account Number :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.acNumber}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>UPI Id :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.upiId}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'><strong>UPI Number :</strong> </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.upiNumber}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={9} className="mb-4">
                    </Col>
                    <Col md={3}>
                        {/* <Button
                            title={"Manually Add Transaction"}
                            onClick={() => setIsManualPay(!isManualPay)}
                            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none', float: 'right' }}>
                            {isManualPay ? "Cancel" : "Manual Txn"}
                        </Button> */}
                    </Col>
                    {isManualPay ?
                        <Col md={12} className='mt-4'>
                            <CardTitle><b>Manual Transaction</b></CardTitle>
                            <AvForm onValidSubmit={handleValidSubmit} ref={form}>
                                <AvField
                                    name="planId"
                                    label="Plan *"
                                    type="select"
                                    required
                                >
                                    <option value="">Select Plan</option>
                                    {plans?.map((item) => (
                                        <option value={item?._id}>{item?.title}</option>
                                    ))}
                                </AvField>
                                <div className="mt-3">
                                    <AvField
                                        name="txnId"
                                        label="Transaction ID *"
                                        placeholder="Enter the transaction id from razorPay or apple pay"
                                        required
                                    />
                                </div>
                                <Row className='mt-3'>
                                    <Col md={6}>
                                        <button
                                            className="btn btn-primary w-100 waves-effect waves-light"
                                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                            onClick={() => { setIsManualPay(false) }}
                                            type="reset"
                                        >
                                            Cancel
                                        </button>
                                    </Col>
                                    <Col md={6}>
                                        <button
                                            className="btn btn-primary w-100 waves-effect waves-light"
                                            type="submit"
                                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                        >
                                            Submit
                                        </button>
                                    </Col>
                                </Row>
                            </AvForm>
                        </Col>
                        : null}
                </Row>
                {userData?.trans?.length ?
                    <>
                        <h5 className='text-left my-2 mb-4 mt-4 text-uppercase text-dark' style={{ display: 'flex', alignItems: 'center', gap: 5 }}> <span style={{ color: '#4bac4f' }}>₹</span><b>Current Plan</b></h5>
                        {userData?.trans?.map((item) => (
                            <Row key={item?._id} className='box-gary'>
                                <Col md={3} className='mt-0'>
                                    <span className='key'><strong>Plan :</strong> </span>
                                </Col>
                                <Col md={3} className='mt-0 text-start'>
                                    <span className='value text-uppercase'>{item?.title}</span>
                                </Col>
                                <Col md={3} className='mt-0'>
                                    <span className='key'><strong>Source :</strong> </span>
                                </Col>
                                <Col md={3} className='mt-0 text-start'>
                                    <span className='value text-uppercase'>{item?.type}</span>
                                </Col>
                                <Col md={3} className='mt-2'>
                                    <span className='key'><strong>{item?.type == "bank" ? "Bank Ac" : "UPI ID"} :</strong> </span>
                                </Col>
                                <Col md={3} className='mt-2 text-start'>
                                    <span className='value text-uppercase'>{item?.acNumber ? item?.acNumber : item?.upiId}</span>
                                </Col>
                                <Col md={3} className='mt-2'>
                                    <span className='key'><strong>{item?.type == "bank" ? "Ac Holder" : "UPI Number"} :</strong> </span>
                                </Col>
                                <Col md={3} className='mt-2 text-start'>
                                    <span className='value text-uppercase'>{item?.acHolder ? item?.acHolder : item?.upiNumber}</span>
                                </Col>
                                <Col md={3} className='mt-2'>
                                    <span className='key'><strong>TXN_ID :</strong> </span>
                                </Col>
                                <Col md={3} className='mt-2 text-start'>
                                    <span className='value text-uppercase'>{item?.txnId}</span>
                                </Col>
                                <Col md={3} className='mt-2'>
                                    <span className='key'><strong>Amount Paid :</strong> </span>
                                </Col>
                                <Col md={3} className='mt-2 text-start'>
                                    <span className='value text-uppercase'>INR {item?.paid}</span>
                                </Col>
                                <Col md={3} className='mt-2'>
                                    <span className='key'><strong>Status :</strong> </span>
                                </Col>
                                <Col md={3} className='mt-2 text-start'>
                                    {item?.status == "Approved" ?
                                        <span className='value text-uppercase' style={{ backgroundColor: "green", padding: "2px 5px", color: "white", fontWeight: 'bold' }}>{item?.status}</span>
                                        : null}
                                    {item?.status == "Pending" ?
                                        <span className='value text-uppercase' style={{ backgroundColor: "yellow", padding: "2px 5px", color: "black", fontWeight: 'bold' }}>{item?.status}</span>
                                        : null}
                                    {item?.status == "Rejected" ?
                                        <span className='value text-uppercase' style={{ backgroundColor: "red", padding: "2px 5px", color: "white", fontWeight: 'bold' }}>{item?.status}</span>
                                        : null}
                                    {item?.status == "Failed" ?
                                        <span className='value text-uppercase' style={{ backgroundColor: "red", padding: "2px 5px", color: "white", fontWeight: 'bold' }}>{item?.status}</span>
                                        : null}
                                    <Button
                                        onClick={() => {
                                            if (checkPermission("paidUserRequests", "update")) {
                                                setTemp({ ...item, actionType: "PayStatus", to: "Rejected" });
                                                setPayConfirm(true);
                                            } else
                                                toast.error("You are not authorized for this action!");
                                        }}
                                        style={{ backgroundColor: "red", border: "none", marginLeft: 10 }}>
                                        <span className='text-uppercase' style={{ color: "white", fontWeight: 'bold' }}>Reject</span>
                                    </Button>
                                </Col>
                                <Col md={3} className='mt-2'>
                                    <span className='key'><strong>Amount Due :</strong> </span>
                                </Col>
                                <Col md={3} className='mt-2 text-start'>
                                    <span className='value text-uppercase'>{item?.due ? `INR ${item?.due}` : ''}</span>
                                    <Button
                                        onClick={() => { setDueModal(true) }}
                                        title={"Receipt"}
                                        className={`btn_status`}
                                        style={{ marginLeft: item?.due ? 20 : 0 }}
                                    >
                                        Update
                                    </Button>
                                </Col>
                                <Col md={3} className='mt-2'>
                                    <span className='key'><strong>Paid on:</strong> </span>
                                </Col>
                                <Col md={3} className='mt-2 text-start'>
                                    <span className='value text-uppercase'>{moment.tz(item?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}</span>
                                </Col>
                                <Col md={3} className='mt-2'>
                                    <span className='key'><strong>Receipt :</strong> </span>
                                </Col>
                                <Col md={3} className='mt-2 text-start'>
                                    <Button
                                        onClick={() => { setCurrentImage(item?.receipt); setImageModal(true) }}
                                        title={"Receipt"}
                                        className={`btn_status btn_status_new active`}
                                    >
                                        <i className='ri-image-line' style={{ fontSize: 12, color: '#fff' }} />
                                    </Button>
                                </Col>
                                <hr className='mt-3'></hr>
                            </Row>
                        ))}
                    </>
                    : null}

                {txn?.txn ?
                    <>
                        <h5 className='text-left my-2 mb-4 mt-4 text-uppercase text-dark' style={{ display: 'flex', alignItems: 'center', gap: 5 }}> <span style={{ color: '#4bac4f' }}>₹</span><b>Transaction</b></h5>
                        <Row className='box-gary'>
                            <Col md={3} className='mt-0'>
                                <span className='key'><strong>Plan :</strong> </span>
                            </Col>
                            <Col md={3} className='mt-0 text-start'>
                                <span className='value text-uppercase'>{txn?.title}</span>
                            </Col>
                            <Col md={3} className='mt-0'>
                                <span className='key'><strong>Source :</strong> </span>
                            </Col>
                            <Col md={3} className='mt-0 text-start'>
                                <span className='value text-uppercase'>{txn?.type}</span>
                            </Col>
                            <Col md={3} className='mt-2'>
                                <span className='key'><strong>{txn?.type == "bank" ? "Bank Ac" : "UPI ID"} :</strong> </span>
                            </Col>
                            <Col md={3} className='mt-2 text-start'>
                                <span className='value text-uppercase'>{txn?.acNumber ? txn?.acNumber : txn?.upiId}</span>
                            </Col>
                            <Col md={3} className='mt-2'>
                                <span className='key'><strong>{txn?.type == "bank" ? "Ac Holder" : "UPI Number"} :</strong> </span>
                            </Col>
                            <Col md={3} className='mt-2 text-start'>
                                <span className='value text-uppercase'>{txn?.acHolder ? txn?.acHolder : txn?.upiNumber}</span>
                            </Col>
                            <Col md={3} className='mt-2'>
                                <span className='key'><strong>TXN_ID :</strong> </span>
                            </Col>
                            <Col md={3} className='mt-2 text-start'>
                                <span className='value text-uppercase'>{txn?.txnId}</span>
                            </Col>
                            <Col md={3} className='mt-2'>
                                <span className='key'><strong>Amount Paid :</strong> </span>
                            </Col>
                            <Col md={3} className='mt-2 text-start'>
                                <span className='value text-uppercase'>INR {txn?.paid}</span>
                            </Col>

                            <Col md={3} className='mt-2'>
                                <span className='key'><strong>Status :</strong> </span>
                            </Col>
                            <Col md={3} className='mt-2 text-start'>
                                {txn?.status == "Approved" ?
                                    <span className='value text-uppercase' style={{ backgroundColor: "green", padding: "2px 5px", color: "white", fontWeight: 'bold' }}>{txn?.status}</span>
                                    : null}
                                {txn?.status == "Pending" ?
                                    <span className='value text-uppercase' style={{ backgroundColor: "yellow", padding: "2px 5px", color: "black", fontWeight: 'bold' }}>{txn?.status}</span>
                                    : null}
                                {txn?.status == "Rejected" ?
                                    <span className='value text-uppercase' style={{ backgroundColor: "red", padding: "2px 5px", color: "white", fontWeight: 'bold' }}>{txn?.status}</span>
                                    : null}
                                {txn?.status == "Failed" ?
                                    <span className='value text-uppercase' style={{ backgroundColor: "red", padding: "2px 5px", color: "white", fontWeight: 'bold' }}>{txn?.status}</span>
                                    : null}
                                <Button style={{ backgroundColor: Utils.themeColorDark, border: "none", marginLeft: 10 }}
                                    onClick={() => {
                                        if (checkPermission("paidUserRequests", "update")) {
                                            setCurrentData({ ...txn, actionType: "PayStatus", to: "Approved" });
                                            setConfirm(true);
                                        } else
                                            toast.error("You are not authorized for this action!");
                                    }}
                                >
                                    <span className='text-uppercase' style={{ color: "white", fontWeight: 'bold' }}>Approve</span>
                                </Button>
                                <Button
                                    onClick={() => {
                                        if (checkPermission("paidUserRequests", "update")) {
                                            setCurrentData({ ...txn, actionType: "PayStatus", to: "Rejected" });
                                            setConfirm(true);
                                        } else
                                            toast.error("You are not authorized for this action!");
                                    }}
                                    style={{ backgroundColor: "red", border: "none", marginLeft: 10 }}>
                                    <span className='text-uppercase' style={{ color: "white", fontWeight: 'bold' }}>Reject</span>
                                </Button>
                            </Col>
                            <Col md={3} className='mt-2'>
                                <span className='key'><strong>Amount Due :</strong> </span>
                            </Col>
                            <Col md={3} className='mt-2 text-start'>
                                <span className='value text-uppercase'>INR {txn?.paid}</span>
                            </Col>
                            <Col md={3} className='mt-2'>
                                <span className='key'><strong>Paid on:</strong> </span>
                            </Col>
                            <Col md={3} className='mt-2 text-start'>
                                <span className='value text-uppercase'>{moment.tz(txn?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}</span>
                            </Col>
                            <Col md={3} className='mt-2'>
                                <span className='key'><strong>Receipt :</strong> </span>
                            </Col>
                            <Col md={3} className='mt-2 text-start'>
                                <Button
                                    onClick={() => { setCurrentImage(txn?.receipt); setImageModal(true) }}
                                    title={"Receipt"}
                                    className={`btn_status btn_status_new active`}
                                >
                                    <i className='ri-image-line' style={{ fontSize: 12, color: '#fff' }} />
                                </Button>
                            </Col>
                            <hr className='mt-3'></hr>
                        </Row>
                    </>
                    : null}
            </ModalBody>
        </Modal >
    )
}

export default UserProfile;